<template>
  <div class="signly-action-button" :style="buttonContainerStyle">
    <button :class="buttonClass" :style="buttonStyle" :disabled="retrying" @click="onOk">
      <span v-if="retrying" :style="textStyle">{{ $t('actionButton.retrying') }}</span>
      <span v-else :style="textStyle">{{ $t('actionButton.retry') }}</span>
    </button>
  </div>
</template>

<script>
import { video } from '@/components/mixins/video'
import Defaults from '@/libs/defaults'

export default {
  name: 'RetryButton',
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    },
    requestingTranslation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      retrying: false
    }
  },
  computed: {
    buttonContainerStyle () {
      return {
        paddingBottom: Defaults.actionButton.containerPaddingBottom[this.videoSize]
      }
    },
    buttonStyle () {
      return {
        fontSize: Defaults.actionButton.fontSize[this.videoSize],
        paddingTop: Defaults.actionButton.paddingY[this.videoSize],
        paddingBottom: Defaults.actionButton.paddingY[this.videoSize],
        outlineWidth: Defaults.actionButton.outlineWidth[this.videoSize],
        borderWidth: Defaults.actionButton.borderWidth[this.videoSize],
        outlineColor: 'white'
      }
    },
    textStyle () {
      return {
        fontSize: Defaults.actionButton.fontSize[this.videoSize]
      }
    },
    buttonClass () {
      return [
        `${this.retrying ? 'disabled' : ''}`
      ]
    }
  },
  methods: {
    onOk () {
      this.retrying = true
      setTimeout(() => {
        this.$emit('retryConnection')
        this.retrying = false
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
