<template>
  <div id="signly-sign-language-chooser">
    <div id="signly-sign-language-options">
      <div
          v-for="signLanguage in supportedSignLanguages"
          :key="signLanguage.id"
          class="signly-sign-language-button"
          :class="contentClass"
          :style="buttonStyle"
          @click="setLocale(signLanguage.locale)"
      >
        <input type="radio" :id="signLanguage.identifier" :value="signLanguage.locale" v-model="selectedSignLanguage">
        <h2>{{ signLanguage.acronym }}</h2>
        <p>{{ signLanguage.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlySignLanguageChooser',
  mixins: [video],
  props: {
    supportedSignLanguages: {
      type: Array,
      default: () => []
    },
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedSignLanguage: ''
    }
  },
  computed: {
    buttonStyle () {
      return {
        fontSize: Defaults.languageChooser.fontSize[this.videoSize]
      }
    },
    contentClass () {
      return `signly-language-chooser-${this.videoSize}`
    }
  },
  methods: {
    setLocale (locale) {
      this.$i18n.locale = locale
      window.localStorage.setItem('selectedLocale', locale)
      window.location.reload()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
