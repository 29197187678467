<template>
  <div id="signly-settings">
    <div v-if="supportedSignLanguages.length > 0" :style="containerStyle">
      <a class="signly-link" @click.prevent="applySettings">
        <div id="signly-settings-back">
          <div>
            <fa
                icon="chevron-left"
                :size="iconSize"
                class="signly-header-icon"
            />
          </div>

          <h3 :style="settingsTitleStyle">{{ $t('settings.settings') }}</h3>
        </div>
      </a>

      <hr class="signly-settings-separator" :style="hrStyle">

      <div id="signly-settings-language-options">
        <div
            class="signly-settings-language-option-item"
            :style="itemStyle"
            v-for="signLanguage in supportedSignLanguages"
            :key="signLanguage.id"
        >
          <BasicRadio
              :value="signLanguage.locale"
              :model-value.sync="selectedLocale"
              :video-width="videoWidth"
              :style="radioStyle"
          />

          <div>
            <h3 style="font-weight: bold;" :style="settingsTitleStyle">{{ signLanguage.acronym }}</h3>
            <p :style="settingsDescriptionStyle">{{ signLanguage.name }}</p>
          </div>
        </div>
      </div>

      <hr class="signly-settings-separator" :style="hrStyle">

      <div id="signly-settings-text-options">
        <BasicCheckbox :model-value.sync="showingCaptions" :video-width="videoWidth" />

        <p :style="settingsTitleStyle">{{ $t('settings.textOverlay') }}</p>
      </div>
    </div>
    <div v-else>
      Loading...
    </div>
  </div>
</template>

<script>
import signly from '@/libs/signly'
import BasicRadio from '@/components/BasicRadio.vue'
import BasicCheckbox from '@/components/BasicCheckbox.vue'
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlySettings',
  mixins: [video],
  components: { BasicCheckbox, BasicRadio },
  props: {
    showSettings: {
      type: Boolean
    },
    showCaptions: {
      type: Boolean
    },
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  data () {
    const selectedLocale = localStorage.getItem('selectedLocale')
    const showingCaptions = localStorage.getItem('showCaptions') ? localStorage.getItem('showCaptions') === 'true' : true

    return {
      initialSelectedLocale: selectedLocale,
      selectedLocale: selectedLocale,
      showingCaptions: showingCaptions,
      supportedSignLanguages: []
    }
  },
  async mounted () {
    this.supportedSignLanguages = await signly.getSupportedSignLanguages(window.location.href)
  },
  destroyed () {
    this.applySettings()
  },
  computed: {
    containerStyle () {
      return {
        fontSize: Defaults.signlySettings.fontSize[this.videoSize]
      }
    },
    iconSize () {
      return Defaults.signlySettings.iconSize[this.videoSize]
    },
    hrStyle () {
      return {
        marginTop: Defaults.signlySettings.marginY[this.videoSize],
        marginBottom: Defaults.signlySettings.marginY[this.videoSize]
      }
    },
    radioStyle () {
      return {
        marginRight: Defaults.signlySettings.textMargin[this.videoSize]
      }
    },
    itemStyle () {
      return {
        margin: Defaults.signlySettings.itemMargins[this.videoSize]
      }
    },
    settingsTitleStyle () {
      return {
        marginLeft: Defaults.signlySettings.textMargin[this.videoSize],
        fontSize: Defaults.signlySettings.fontSize[this.videoSize],
        color: 'white'
      }
    },
    settingsDescriptionStyle () {
      return {
        marginLeft: Defaults.signlySettings.textMargin[this.videoSize],
        fontSize: Defaults.signlySettings.descriptionFontSize[this.videoSize],
        color: 'white'
      }
    }
  },
  methods: {
    applySettings () {
      this.$i18n.locale = this.selectedLocale
      window.localStorage.setItem('selectedLocale', this.selectedLocale)
      window.localStorage.setItem('showCaptions', this.showingCaptions)

      this.$emit('update:showCaptions', this.showingCaptions)
      this.$emit('update:showSettings', false)

      if (this.initialSelectedLocale !== this.selectedLocale) {
        window.location.reload()
      }
    }
  }
}
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}
</style>
