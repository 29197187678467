const Defaults = Object.freeze({
  video: {
    INITIAL_WIDTH: 220,
    MAX_WIDTH: 420,
    MIN_WIDTH: 120,
    LARGE_WIDTH: 320,
    MEDIUM_WIDTH: 220,
    SMALL_WIDTH: 180,
    WIDTH_INCR: 20,
    RATIO: (9 / 16),
    BORDER_SIZE: 1,
    iconSize: {
      xs: 'lg',
      sm: '2x',
      md: '3x',
      lg: '4x'
    },
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '18px',
      lg: '22px'
    },
    poster: 'https://cdn.signly.co/images/transparent-poster.png'
  },
  window: {
    LARGE_WIDTH: 768
  },
  menu: {
    SMALL_BUTTON_WIDTH: 90,
    MEDIUM_BUTTON_WIDTH: 120,
    BUTTON_OFFSET: 4,
    height: {
      xs: '22px',
      sm: '32px',
      md: '48px',
      lg: '64px'
    },
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '18px',
      lg: '22px'
    },
    iconSize: {
      xs: 'lg',
      sm: '2x',
      md: '3x',
      lg: '4x'
    }
  },
  logo: {
    height: {
      xs: '36px',
      sm: '48px',
      md: '60px',
      lg: '72px'
    }
  },
  toolbar: {
    logoHeight: {
      xs: '14px',
      sm: '22px',
      md: '30px',
      lg: '40px'
    },
    iconSize: {
      xs: 'sm',
      sm: 'lg',
      md: '2x',
      lg: '3x'
    },
    padding: {
      xs: '4px 8px 4px 8px',
      sm: '6px 10px 6px 10px',
      md: '8px 14px 8px 14px',
      lg: '10px 18px 10px 18px'
    }
  },
  header: {
    height: {
      xs: '16px',
      sm: '26px',
      md: '44px',
      lg: '60px'
    },
    logoHeight: {
      xs: '14px',
      sm: '22px',
      md: '30px',
      lg: '50px'
    },
    logoWidth: {
      xs: '36px',
      sm: '54px',
      md: '66px',
      lg: '110px'
    },
    buttonSize: {
      xs: '14px',
      sm: '24px',
      md: '34px',
      lg: '50px'
    },
    iconSize: {
      xs: 'xs',
      sm: 'sm',
      md: 'lg',
      lg: '2x'
    },
    outlineWidth: {
      xs: '2px',
      sm: '3px',
      md: '4px',
      lg: '5px'
    }
  },
  overlayText: {
    fontSize: {
      xs: '8px',
      sm: '12px',
      md: '14px',
      lg: '18px'
    },
    lineHeight: {
      xs: '1',
      sm: '1.2',
      md: '1.2',
      lg: '1.2'
    },
    paddingBottom: {
      xs: '4px',
      sm: '4px',
      md: '6px',
      lg: '10px'
    }
  },
  signlySettings: {
    iconSize: {
      xs: 'xs',
      sm: 'sm',
      md: 'lg',
      lg: '2x'
    },
    marginY: {
      xs: '8px',
      sm: '12px',
      md: '18px',
      lg: '25px'
    },
    textMargin: {
      xs: '4px',
      sm: '8px',
      md: '12px',
      lg: '14px'
    },
    itemMargins: {
      xs: '4px 4px 4px 0',
      sm: '6px 6px 6px 0',
      md: '12px 8px 12px 0',
      lg: '14px 10px 14px 0'
    },
    fontSize: {
      xs: '8px',
      sm: '12px',
      md: '14px',
      lg: '18px'
    },
    descriptionFontSize: {
      xs: '6px',
      sm: '8px',
      md: '10px',
      lg: '14px'
    }
  },
  actionButton: {
    fontSize: {
      xs: '10px',
      sm: '12px',
      md: '16px',
      lg: '22px'
    },
    paddingY: {
      xs: '4px',
      sm: '6px',
      md: '10px',
      lg: '14px'
    },
    borderWidth: {
      xs: '2px',
      sm: '3px',
      md: '4px',
      lg: '5px'
    },
    outlineWidth: {
      xs: '2px',
      sm: '3px',
      md: '4px',
      lg: '5px'
    },
    containerPaddingBottom: {
      xs: '4px',
      sm: '4px',
      md: '6px',
      lg: '10px'
    }
  },
  languageChooser: {
    fontSize: {
      xs: '6px',
      sm: '8px',
      md: '10px',
      lg: '14px'
    }
  },
  alerts: [
    {
      type: 'selectLanguage'
    },
    {
      type: 'notTranslated',
      icon: 'sign-language'
    },
    {
      type: 'inTranslation'
    },
    {
      type: 'translated'
    },
    {
      type: 'requestSent'
    },
    {
      type: 'serverError'
    }
  ],
  images: {
    baseUrl: 'https://cdn.signly.co/images/'
  },
  textSegment: {
    playButtonWidth: 26,
    borderWidth: 3,
    minContrastRatio: 3,
    signIconSize: 14,
    signIconSizeDecrease: 6,
    signIconLimit: 20,
    borderLightColor: '#c9c9c9',
    borderDarkColor: '#333333'
  },
  website: {
    baseUrl: 'https://signly.co'
  },
  signlyServer: {
    baseUrl: 'https://signly.azurewebsites.net/api/v1/public'
  }
})

export default Defaults
