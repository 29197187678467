<template>
  <div>
    <button
        ref="expandButton"
        :title="`${isMaxVideoWidth ? $t('header.expandWindowDisabled') : $t('header.expandWindow')}`"
        :class="expandButtonClass"
        :style="buttonStyle"
        :aria-label="`${isMaxVideoWidth ? $t('header.expandWindowDisabled') : $t('header.expandWindow')}`"
        @click.prevent="onExpandWindow"
    >
      <fa
          icon="plus"
          :size="iconSize"
          class="signly-header-icon"
      />
    </button>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconSize () {
      return Defaults.header.iconSize[this.videoSize]
    },
    buttonStyle () {
      return {
        width: Defaults.header.buttonSize[this.videoSize],
        height: Defaults.header.buttonSize[this.videoSize],
        outlineWidth: Defaults.header.outlineWidth[this.videoSize]
      }
    },
    isMaxVideoWidth () {
      return this.videoWidth >= Defaults.video.MAX_WIDTH
    },
    expandButtonClass () {
      return [
        'signly-header-button',
        this.isMaxVideoWidth ? 'disabled' : null
      ]
    }
  },
  methods: {
    onExpandWindow () {
      this.$nextTick(() => {
        this.$refs.expandButton.focus()
      })

      if (this.videoWidth + Defaults.video.WIDTH_INCR <= Defaults.video.MAX_WIDTH) {
        this.$emit('update:videoWidth', this.videoWidth + Defaults.video.WIDTH_INCR)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
