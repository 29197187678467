<template>
  <div class="signly-basic-radio" :class="radioClass">
    <input type="radio" v-model="radioModel" :value="value">
    <slot></slot>
  </div>
</template>

<script>
import { video } from '@/components/mixins/video'

export default {
  name: 'BasicRadio',
  mixins: [video],
  props: {
    modelValue: {
      required: true
    },
    value: {
      requires: true
    },
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    radioModel: {
      get () {
        return this.modelValue
      },
      set (newChecked) {
        this.$emit('update:modelValue', newChecked)
      }
    },
    radioClass () {
      return `signly-radio-button-${this.videoSize}`
    }
  }
}

</script>
