<template>
  <div>
    <button
        ref="closeButton"
        :title="$t('header.hideWindow')"
        class="signly-header-button"
        :style="buttonStyle"
        :aria-label="$t('header.hideWindow')"
        @click.prevent="onCloseContainer"
    >
      <fa
          icon="times"
          :size="iconSize"
          class="signly-header-icon"
      />
    </button>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconSize () {
      return Defaults.header.iconSize[this.videoSize]
    },
    buttonStyle () {
      return {
        width: Defaults.header.buttonSize[this.videoSize],
        height: Defaults.header.buttonSize[this.videoSize],
        outlineWidth: Defaults.header.outlineWidth[this.videoSize]
      }
    },
    closeButtonClass () {
      return [
        'signly-header-button'
      ]
    }
  },
  methods: {
    onCloseContainer () {
      this.$nextTick(() => {
        this.$refs.closeButton.focus()
      })

      this.$emit('closeWindow')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
