<template>
  <div>
    <button
        ref="shrinkButton"
        :title="`${isMinVideoWidth ? $t('header.shrinkWindowDisabled') : $t('header.shrinkWindow')}`"
        :class="shrinkButtonClass"
        :style="buttonStyle"
        :aria-label="`${isMinVideoWidth ? $t('header.shrinkWindowDisabled') : $t('header.shrinkWindow')}`"
        @click.prevent="onShrinkWindow"
    >
      <fa
          icon="minus"
          :size="iconSize"
          class="signly-header-icon"
      />
    </button>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconSize () {
      return Defaults.header.iconSize[this.videoSize]
    },
    buttonStyle () {
      return {
        width: Defaults.header.buttonSize[this.videoSize],
        height: Defaults.header.buttonSize[this.videoSize],
        marginRight: '2px',
        outlineWidth: Defaults.header.outlineWidth[this.videoSize]
      }
    },
    isMinVideoWidth () {
      return this.videoWidth <= Defaults.video.MIN_WIDTH
    },
    shrinkButtonClass () {
      return [
        'signly-header-button',
        this.isMinVideoWidth ? 'disabled' : null
      ]
    }
  },
  methods: {
    onShrinkWindow () {
      this.$nextTick(() => {
        this.$refs.shrinkButton.focus()
      })

      if (this.videoWidth - Defaults.video.WIDTH_INCR >= Defaults.video.MIN_WIDTH) {
        this.$emit('update:videoWidth', this.videoWidth - Defaults.video.WIDTH_INCR)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
