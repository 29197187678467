<template>
  <div id="signly-overlay-text" :style="containerStyle">
    <span :style="spanStyle" v-if="overlayText">{{ overlayText }}</span>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'OverlayText',
  mixins: [video],
  props: {
    overlayText: {
      type: String,
      default: ''
    },
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    containerStyle () {
      return {
        fontSize: Defaults.overlayText.fontSize[this.videoSize],
        lineHeight: Defaults.overlayText.lineHeight[this.videoSize],
        paddingBottom: Defaults.overlayText.paddingBottom[this.videoSize],
        textAlign: 'center'
      }
    },
    spanStyle () {
      return {
        fontSize: Defaults.overlayText.fontSize[this.videoSize],
        lineHeight: Defaults.overlayText.lineHeight[this.videoSize]
      }
    }
  }
}
</script>
