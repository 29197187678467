<template>
  <div id="signly-toolbar" :style="toolbarStyle">
    <div id="signly-toolbar-logo">
      <div>
        <a :href="signlyWebsite" target="_blank" :aria-label="$t('header.title')" :title="$t('header.title')">
          <img
              :src="logoSrc"
              alt="Signly logo"
              :style="{ 'max-height': logoHeight }"
          >
        </a>
      </div>
    </div>

    <div id="signly-toolbar-icon" :class="iconClass" tabindex="0" @keyup.space.enter="onShowSettings">
      <fa icon="sign-language" :size="iconSize" @click="onShowSettings"/>
    </div>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'
export default {
  name: 'SignlyToolbar',
  mixins: [video],
  props: {
    showSettings: {
      type: Boolean,
      default: false
    },
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    logoSrc () {
      return Defaults.images.baseUrl + 'signly-word-white.svg'
    },
    logoHeight () {
      return Defaults.toolbar.logoHeight[this.videoSize]
    },
    iconSize () {
      return Defaults.toolbar.iconSize[this.videoSize]
    },
    signlyWebsite () {
      return Defaults.website.baseUrl
    },
    toolbarStyle () {
      return {
        padding: Defaults.toolbar.padding[this.videoSize]
      }
    },
    iconClass () {
      return this.showSettings && 'active'
    }
  },
  methods: {
    onShowSettings () {
      this.$emit('update:showSettings', !this.showSettings)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
