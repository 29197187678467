<template>
  <div class="signly-slider">
    <label class="signly-switch" :class="switchClass" for="signly-slider" tabindex="0" @keyup.space.enter="checked = !checked">
      <input type="checkbox" id="signly-slider" v-model="checked">
      <div class="signly-slider round" :class="switchClass"></div>
    </label>
  </div>
</template>

<script>
import { video } from '@/components/mixins/video'

export default {
  name: 'BasicCheckbox',
  mixins: [video],
  props: {
    modelValue: {
      type: Boolean
    },
    videoWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    checked: {
      get () {
        return this.modelValue
      },
      set (newChecked) {
        this.$emit('update:modelValue', newChecked)
      }
    },
    switchClass () {
      return `video-size-${this.videoSize}`
    }
  }
}
</script>
