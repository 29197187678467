<template>
  <div class="signly-action-button" :style="buttonContainerStyle">
    <button :class="buttonClass" :style="buttonStyle" :disabled="requestingTranslation" @click="onSendRequest">
      <span v-if="requestingTranslation" :style="textStyle">{{ $t('actionButton.requestingTranslation') }}</span>
      <span v-else :style="textStyle">{{ $t('actionButton.requestTranslation') }}</span>
    </button>
  </div>
</template>

<script>
import { video } from '@/components/mixins/video'
import Defaults from '@/libs/defaults'

export default {
  name: 'RequestTranslationButton',
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    },
    requestingTranslation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonContainerStyle () {
      return {
        paddingBottom: Defaults.actionButton.containerPaddingBottom[this.videoSize]
      }
    },
    buttonStyle () {
      return {
        fontSize: Defaults.actionButton.fontSize[this.videoSize],
        paddingTop: Defaults.actionButton.paddingY[this.videoSize],
        paddingBottom: Defaults.actionButton.paddingY[this.videoSize],
        outlineWidth: Defaults.actionButton.outlineWidth[this.videoSize],
        borderWidth: Defaults.actionButton.borderWidth[this.videoSize],
        outlineColor: 'white'
      }
    },
    textStyle () {
      return {
        fontSize: Defaults.actionButton.fontSize[this.videoSize]
      }
    },
    buttonClass () {
      return [
        `${this.requestingTranslation ? 'disabled' : ''}`
      ]
    }
  },
  methods: {
    onSendRequest () {
      this.$emit('update:requestingTranslation', true)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
